import { navigate } from '@reach/router';
import React, { FC, useEffect } from 'react';
import { track } from 'utils/analytics';

interface NextStepProps {}

const NextStep: FC<NextStepProps> = () => {
  useEffect(() => {
    track('Next Step Viewed');
    navigate('/pdfs/next-step.pdf');
  }, []);

  return <></>;
};

export default NextStep;
